import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import Main from "./pages/main";
import Page404 from "./pages/page404";
// import DriverManual from "./pages/driverManual";
// import DispatcherManual from "./pages/dispatcherManual";

function App() {
    return (
        <>
            <Helmet>
                <title>Челнок — транспорт для современного города</title>
                <meta name="description" content="Челнок — мобильное приложение для твоих поездок" />
                <link href="/css/main.css" rel="stylesheet" />
                <script src="/js/app.js" type="text/javascript" />
            </Helmet>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main />} />
                    {/* <Route path="/driver-manual" element={<DriverManual />} />
                    <Route path="/dispatcher-manual" element={<DispatcherManual />} /> */}
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
