export const Main = () => {
  return(
    <div className="body">
            <main id="main" className="main">
                <header id="header" className="header px-0 px-xs-8 px-sm-0 px-md-24 px-lg-48 px-xl-80">
                    <div className="container-fixed d-fl ai-c w-100">
                        <a href="#main" className="header__logo fade-in-top-small a-dr-05 scroll-main">
                            <svg width="48" height="48" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M51.1835 17.4927C50.9833 31.7988 26.0349 48.0048 14.9708 36.9412C3.90656 25.8774 20.1132 0.927982 34.4186 0.727808C43.809 0.596409 51.3149 8.10232 51.1835 17.4927Z" fill="white"/>
                                <path d="M9.41935 13.6188C17.3816 11.3753 21.3628 10.2535 24.1338 10.3392C33.6406 10.6333 41.2786 18.2714 41.5727 27.7781C41.6584 30.5491 40.5705 34.4102 38.3946 42.1323V42.1323C37.2969 46.0283 36.748 47.9763 36.2551 48.6933C34.3784 51.4236 31.3742 51.908 28.7345 49.9058C28.0413 49.38 26.7335 47.4449 24.1178 43.5746C19.926 37.3724 14.8647 32.3805 7.76464 27.7493C3.9662 25.2716 2.06698 24.0328 1.50947 23.2893C-0.401212 20.7412 0.0606961 17.7191 2.64526 15.8581C3.39939 15.315 5.40605 14.7496 9.41935 13.6188V13.6188Z" fill="#7E50FB"/>
                                <path d="M51.1835 17.4927C50.9833 31.7988 26.0349 48.0048 14.9708 36.9412C3.90656 25.8774 20.1132 0.927982 34.4186 0.727808C43.809 0.596409 51.3149 8.10232 51.1835 17.4927Z" fill="#FFD000"/>
                            </svg>
                        </a>

                        <input id="globalMenu" type="checkbox" className="header-menu-control"/>
                        <label htmlFor="globalMenu" className="burger-menu ml-24 fade-in-top-small a-dr-05 a-dl-02 scroll-main"><span className="menu-line"></span></label>
                        <div id="sideManu" className="menu-container ai-c">
                            <label title="Назад" htmlFor="globalMenu" className="link-arrow link-arrow--white link-arrow--left d-md-none">
                                <span className="icon-back"></span>
                            </label>
                            <div className="d-fl fl-col fl-md-row ai-md-c w-100 jc-sm-c rg-24 cg-24 cg-lg-40 fade-in-top-small a-dr-05 a-dl-04 scroll-main">
                                {/* <Link className="menu-container__link menu" to={"/"} >Общая информация</Link>
                                <Link className="menu-container__link menu" to={"/driver-manual"}>Инструкция для водителя</Link>     
                                <Link className="menu-container__link menu" to={"/dispatcher-manual"}>Инструкция для диспечера</Link>*/}
                                <a class="menu-container__link menu ml-24" href="#about">Общая информация</a>
                                <a class="menu-container__link menu" href="#mobile-app">Мобильное приложение пассажира</a>
                                <a class="menu-container__link menu" href="#mobile-app-driver">Мобильное приложение водителя</a>
                                <a class="menu-container__link menu" href="#app-dispatcher">Панель управления диспетчера</a>
                                <a class="menu-container__link menu" href="#cost">Стоимость</a>
                                <div className="d-fl fl-col rg-16 pt-24 d-md-none">
                                    <a href="./privacy-policy.pdf" className="menu-container__link docs">Политика Конфиденциальности</a>
                                    <a href="./rules.pdf" className="menu-container__link docs">Правила поведения в транспортном средстве</a>
                                    <a href="./personal-data.pdf" className="menu-container__link docs">Согласие на обработку персональных данных</a>
                                    <a href="./user_agreement.pdf" className="menu-container__link docs">Пользовательское соглашение мобильного приложения</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>


                <div className="main-container d-fl fl-col jc-c ai-c">
                    <h1 className="main__header mr-md-80 slide-left a-dr-07 scroll-main">Челнок</h1>
                </div>
            </main>

            <div className="d-fl fl-col ai-c px-16 px-xs-24 px-lg-40 px-xl-80">
                 <section id="about" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll">
                    <div className="about container-fixed">
                        <h2 className="h2 t-c fade-in-top-small a-dr-06 js-scroll">Общие характеристики программного продукта «Челнок»</h2>

                        <p className="services__text mt-20">
                            Программный продукт предназначен для осуществления перевозок пассажиров по требованию, который позволяет распределять
                            заказы по транспортным средствам. Маршруты перевозок генерируются динамически на основе спроса в реальном времени. 
                        </p>
                        <p className=" services__text mt-20">Технология позволяет:</p>

                        <ul className="services__text mt-12">
                            <li className="services__text mt-4 ">
                                Отказаться от фиксированных маршрутов, тем самым сократив количество подвижного состава, 
                                расходы на транспортную работу, стоимость перевозки для клиента;
                            </li>
                            <li className="services__text mt-4 ">
                                Повысить эффективность перевозок за счет оптимального агрегирования пассажиров в транспортные средства.
                            </li>
                        </ul>

                        <p  className="services__text mt-20">
                            Гибкость сервиса позволяет применять его при различных сценариях пассажирских перевозок: перевозки общественным транспортом,
                            пригородные, ночные, корпоративные и школьные перевозки, перевозки маломобильных граждан. Возможна интеграция программного 
                            продукта в MaaS-системы.
                        </p>

                        <p className="services__text mt-20">
                            Программный продукт состоит из:
                            <ul>
                                <li className="services__text mt-4 ml-24">Мобильного приложения пользователя</li>
                                <li className="services__text mt-4 ml-24">Мобильного приложения водителя</li>
                                <li className="services__text mt-4 ml-24">Панели управления диспетчера</li>
                                <li className="services__text mt-4 ml-24">Алгоритмического модуля</li>    
                            </ul>
                        </p>

                        <p className="services__text mt-20">
                            Тип ЭВМ: платформа: Intel Cascade Lake (standard-v2); <br />
                            Процессор: Intel Xeon Gold 6230; количество ядер (vCPU)  = 32, <br />
                            Базовая тактовая частота = 2.10 ГГц. <br />
                            Язык программирования: Python, Java Script, Java, Kotlin, Swift <br/>
                            ОС: Linux
                        </p>
                    </div>
                </section>     
                      
                <section id="mobile-app" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll">
                    <div className="mobile-app container-fixed">
                        <h2 className="h2 t-c fade-in-top-small a-dr-06 js-scroll mt-24 mb-24">Мобильное приложение пассажира</h2>
                        <span className="services__text">Функционал позволяет:</span>

                         <ul className="services__text mt-24">
                            <li className='mt-4 ml-24'>
                                Выбрать точки посадки и высадки. Алгоритм проложит до них релевантный пеший путь либо до уже
                                существующей остановки общественного транспорта, либо до «виртуальной» остановки – точки на карте, в которой транспортное 
                                средство может осуществлять посадку/высадку пассажиров согласно ПДД;
                            </li>
                            <li className='mt-4 ml-24'>Выбрать кол-во пассажиров;</li>
                            <li className='mt-4 ml-24'>
                                Оплатить поездку банковской картой через подключенный к мобильному приложению функционал интернет-эквайринга;
                            </li>
                            <li className='mt-4 ml-24'>При необходимости отменить поездку;</li>
                            <li className='mt-4 ml-24'>Сканировать QR-код на входе в транспортное средство, чтобы начать поездку и уведомить водителя о своем присутствии;</li>
                            <li className='mt-4 ml-24' >
                                Отслеживать статус заказа (время ожидания транспортного средства, время в пути до точки высадки,
                                информация о транспортном средстве, маршрут следования);
                            </li>
                            <li className='mt-4 ml-24'>Ознакомиться с юридическими документами на перевозку и обратиться в техническую поддержку;</li>
                            <li className='mt-4 ml-24'>Получать уведомления от разработчика о работе сервиса.</li>
                        </ul>

                        <p className="services__text mt-12">
                            Мобильное приложение разработано для мобильных устройств на платформах iOS (текущая версия – 1.11.1) и Android (текущая версия – 2.20.2).
                            Требования к ОС: iOS от 13.0 и выше, Android – от 7.0 и выше.
                        </p>

                        <p className="services__text mt-12">
                            <i>Ссылка на скачивание: </i>
                            <ul>
                                <li className='mt-4 ml-24'>
                                    <a class="menu-container__link menu" href="https://apps.apple.com/ru/app/челнок-умный-транспорт/id1645326928" rel="noreferrer" target="_blank">App Store</a>
                                </li>
                                <li className='mt-4 ml-24'>
                                    <a class="menu-container__link menu" href="https://apps.rustore.ru/app/com.kmz.mt.client" rel="noreferrer" target="_blank">RuStore</a>
                                </li>
                                <li className='mt-4 ml-24'>
                                    <a class="menu-container__link menu" href="https://play.google.com/store/apps/details?id=com.kmz.mt.client&pli=1" rel="noreferrer" target="_blank">Google Play</a>
                                </li>
                            </ul>
                        </p>
                       
                        <p className="services__text mt-12">
                           <i>Инструкция по установке:</i> 
                           <ul className="services__text">
                            <li className='mt-4 ml-24'>Скачайте мобильное приложение «Челнок» в удобном для Вас магазине приложений.</li>
                            <li className='mt-4 ml-24'>
                                После установки мобильного приложения «Челнок» на своем устройстве до прохождения процедуры регистрации 
                                Пользователю необходимо открыть Мобильное приложение, ознакомиться с Соглашением на обработку персональных данных, 
                                настоящим Соглашением, Политикой конфиденциальности.
                                <span className='note'>
                                        Примечание: В Мобильном приложении Пользователю предоставляются на ознакомление (посредством перехода по активным 
                                        ссылкам на текст документа на экране Устройства) следующие документы: Пользовательское соглашение; 
                                        Политика конфиденциальности; Согласие Пользователя на обработку его персональных данных.
                                </span>
                            </li>
                            <li className='mt-4 ml-24'>
                                Ознакомление и согласие с условиями, изложенными в указанных документах, осуществляется Пользователем путем
                                нажатия активной кнопки «Продолжить». Нажимая «Продолжить», Пользователь выражает свое безусловное согласие с настоящим 
                                Соглашением и также дает свое согласие на обработку Разработчиком персональных данных Пользователя на условиях 
                                Политики конфиденциальности с использованием средств автоматизации и/или без использования таких средств.
                             </li>
                            <li className='mt-4 ml-24'>
                                После ознакомления с указанными выше документами и одновременно их принятия, Пользователь проходит процедуру
                                регистрации в Мобильном приложении. В окне регистрации Пользователь вводит номер мобильного телефона и нажимает активную кнопку
                                «Продолжить», в результате чего на указанный телефон Пользователя поступает смс-код, который необходимо ввести в соответствующую
                                строку, или звонок с телефонного номера, последние 4 (четыре) цифры которого необходимо ввести в соответствующую строку.
                            </li>
                            <li className='mt-4 ml-24'>
                                После ввода смс-кода или последних 4 (четырех) цифр телефонного номера, с которого поступил звонок, (завершения регистрации) 
                                в Мобильном приложении Пользователю предоставляется возможность осуществления дальнейших действий, предусмотренных Мобильным 
                                приложением.
                            </li>
                           </ul>
                        </p>

                        <p className="services__text mt-12">
                            Инструкция по эксплуатации: <a class="menu-container__link menu" href="https://storage.yandexcloud.net/mt-docs/Пользовательское%20соглашение.htm">пользовательское соглашение</a>.
                        </p>
                    </div>
                </section>

                <section id="mobile-app-driver" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll">
                    <div id="driver-manual" className="mobile-app-driver container-fixed mt-24">
                        <h2 className="h2 t-c fade-in-top-small a-dr-06 js-scroll mt-24 mb-24">Мобильное приложение водителя</h2>
                        <span className="services__text">Функционал позволяет:</span>
                        
                        <ul className="services__text mt-12">
                            <li className="mt-4 ml-24">Получать информацию о поступающих заявках на перевозку; </li>
                            <li className="mt-4 ml-24">Управлять статусами заявок;</li>
                            <li className="mt-4 ml-24">
                                Автоматически прокладывать маршрут от текущего местоположения до точки посадки/высадки пассажиров 
                                или мест отдыха/смены;
                            </li>
                            <li className="mt-4 ml-24">Актуализировать маршрут движения с учетом поступающих/отмененных заявок;</li>
                            <li className="mt-4 ml-24">Получать информацию о заказе (последние 6 цифр номера телефона пассажира, кол-во пассажиров, точки посадки и высадки);</li>
                            <li className="mt-4 ml-24">
                                Уведомлять, что транспортное средство прибыло на точку посадки, отслеживать таймер, в течение которого пассажир должен явиться
                                на точку посадки, начинать и завершать поездку либо поставить отметку, что пассажир(-ы) не явился (-ись) в точку посадки;
                            </li>
                            <li className="mt-4 ml-24">Уведомлять о количестве времени, отведенное на перерыв водителя;</li>
                            <li className="mt-4 ml-24">
                                Производить учёт количества входящих/выходящих пассажиров. Мобильное приложение водителя разработано на базе операционной
                                 системы Android (версия 7.0 и выше). Текущая версия – 1.6.4.
                            </li>
                        </ul>

                        <p className="services__text mt-12">
                            <i>Ссылка на скачивание: </i>
                            <ul>
                                <li className="mt-4 ml-24">
                                    <a class="menu-container__link menu" href="https://play.google.com/store/apps/details?id=com.kamaz.mtdrivernew" rel="noreferrer" target="_blank">Google Play</a>
                                </li>
                                <li className="mt-4 ml-24">
                                    <a class="menu-container__link menu" href="https://apps.rustore.ru/app/com.kamaz.mtdrivernew" rel="noreferrer" target="_blank">RuStore</a>
                                </li>
                            </ul>
                        </p>

                        <p className="services__text mt-12">
                           <i>Инструкция по установке:</i> 
                           <ul className="services__text">
                                <li className='mt-4 ml-24'>Скачайте мобильное приложение «Челнок Водитель» в удобном для Вас магазине приложений.</li>
                                <li className='mt-4 ml-24'>
                                    После установки мобильного приложения на своем устройстве до прохождения процедуры регистрации водителю необходимо открыть
                                    Мобильное приложение, выбрать соответствующий город.
                                </li>
                                <li className='mt-4 ml-24'>
                                    Далее вести номер мобильного телефона, привязанный к учетной записи. 
                                    <span className='note'>
                                        Примечание: регистрация в мобильном приложении водителя не осуществляется свободно, допуск предоставляется на основании 
                                        лицензионного договора между автомобильным парком и разработчиком программного продукта.
                                    </span>
                                </li>
                                <li className='mt-4 ml-24'>
                                    Ввести полученный на указанный номер мобильного телефона 4-значный SMS-код.
                                </li>
                           </ul>
                        </p>

                        <p className="services__text mt-12">
                           <i>Инструкция по эксплуатации:</i> <a class="menu-container__link menu" href="https://storage.yandexcloud.net/mt-admin-front/manuals/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F-%D0%B4%D0%BB%D1%8F-%D0%B2%D0%BE%D0%B4%D0%B8%D1%82%D0%B5%D0%BB%D0%B5%D0%B8%CC%86.pdf" rel="noreferrer" target="_blank">Инструкция для водителя</a>
                        </p>
                    </div>
                </section>

                <section  id="app-dispatcher" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll">
                    <div  className="app-dispatcher container-fixed mt-24" id="dispatcher-manual">
                        <h2  className="h2 t-c fade-in-top-small a-dr-06 js-scroll mb-24">Панель управления диспетчера и алгоритм</h2>
                        <span className="services__text">Функционал позволяет:</span>
                        
                        <ul className="services__text mt-12">
                            <li className="mt-4 ml-24">Производить интерактивный мониторинг состояния и движения автопарка, находящегося под управлением сервиса;</li>
                            <li className="mt-4 ml-24">Администрировать состав автопарка и водителей; </li>
                            <li className="mt-4 ml-24">Создавать и редактировать смены водителей;</li>
                            <li className="mt-4 ml-24">Создавать и редактировать профили водителей и транспортных средств;</li>
                            <li className="mt-4 ml-24">Создавать и редактировать профили корпоративных клиентов;</li>
                            <li className="mt-4 ml-24">Осуществлять мониторинг статистики сервиса;</li>
                            <li className="mt-4 ml-24">Управлять настройками ценообразования сервиса, графиком работы сервиса;</li>
                            <li className="mt-4 ml-24">При необходимости осуществлять ручное управление назначением заявок на транспортные средства;</li>
                            <li className="mt-4 ml-24">Cоздавать и редактировать остановки, терминалы, зоны работы сервиса;</li>
                            <li className="mt-4 ml-24">Cоздавать и редактировать профили пользователей, имеющих разные уровни доступа при управлении сервисом.</li>
                        </ul>
                       
                        <p className="services__text mt-12" >
                           <i>Инструкция по установке:</i> <br />
                           <p className='mt-8 ml-24' >
                                Панель управления диспетчера размещается на виртуальном сервере по адресам с доменом mt.dpkapp.ru в сети Интернет.
                                Регистрация не осуществляется свободно, допуск предоставляется на основании лицензионного договора между автомобильным 
                                парком и разработчиком программного продукта путем выдачи логина и пароля. Для авторизации в панели управления диспетчера
                                необходимо ввести выданный логин и пароль.   
                           </p>
                        </p>

                        <p className="services__text mt-12" >
                           <i>Инструкция по эксплуатации:</i> <a class="menu-container__link menu" href="https://storage.yandexcloud.net/mt-admin-front/manuals/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F-%D0%B4%D0%BB%D1%8F-%D0%B4%D0%B8%D1%81%D0%BF%D0%B5%D1%82%D1%87%D0%B5%D1%80%D0%BE%D0%B2.pdf" rel="noreferrer" target="_blank">Инструкция для диспетчеров</a>
                        </p>

                        <p className="services__text mt-12">
                            Алгоритмический модуль позволяет:
                            <ul>
                                <li className="mt-4 ml-24">
                                    Реализовывать логику динамической маршрутизации и динамического назначения заявок на перевозку на транспортные средства с
                                    учётом всех требуемых ограничений и лимитов, а именно: предельного (максимального) значения ожидания прибытия транспортного
                                    средства в точку посадки, предельного (максимального) значения времени нахождения транспортного средства в пути и прибытия в
                                    точку высадки, оптимального расстояния транспортного средства от точки посадки, оптимального коэффициента отклонения от
                                    изначального маршрута для выполнения вновь назначенной заявки;
                                </li>
                                <li className="mt-4 ml-24">
                                    Осуществляет парсинг данных геопозиции устройств водителей, расположенных в транспортных средствах, и устройств пользователей, 
                                    по API картографических систем и электронных справочников городов;
                                </li>
                                <li className="mt-4 ml-24">
                                    На основе big data и исторических данных осуществляет расчет времени подачи транспортного средства к точке посадки,
                                    времени в пути и времени прибытия в точку высадки с учетом дорожной ситуации;
                                </li>
                                <li className="mt-4 ml-24">
                                    Симулирует спрос на основе big data и исторических данных, формирует и прогнозирует точки потенциального спроса, 
                                    где устанавливаются терминалы для нахождения транспортных средств в начале смены и/или в процессе смены в момент 
                                    отсутствия заявок на перевозку;
                                </li>
                                <li className="mt-4 ml-24">
                                    Обеспечивает координацию работы всех компонент программного продукта.
                                </li>
                            </ul>
                        </p>
                    </div>
                </section>  

                <section  className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll">
                    <div id="cost" className="about container-fixed mt-24">
                        <h2 className="h2 t-c fade-in-top-small a-dr-06 js-scroll ">Стоимость лицензии</h2>
                        <div className="d-fl fl-w fl-md-nw jc-c ai-c rg-48 cg-40 mt-48 mt-sm-60">
                            <div className="table-wrapper">
                                <table className="table">                     
                                <tbody>
                                    <tr>
                                        <td className='p-12'>Доступ к технологии</td>
                                        <td className='p-12 licence-column'>
                                            Подключение к технологии «Челнок», включая приложение водителя,
                                            приложение пассажира, панель диспетчера, бэкэнд модуль
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Динамическая оптимизация автопарка</td>
                                        <td className='p-12'>Динамическая маршрутизация в реальном времени</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Обновление</td>
                                        <td className='p-12'>Регулярные автоматические обновления компонентов системы</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Облачный хостинг</td>
                                        <td className='p-12'>Yandex Cloud</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Поддержка</td>
                                        <td className='p-12'>Поддержка личным менеджером, техническая поддержка </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Аналитика</td>
                                        <td className='p-12'>Расширенные онлайн данные о работе сервиса в разрезе часов и дней</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="d-fl fl-w fl-md-nw jc-c ai-c rg-48 cg-40 mt-48 mt-sm-60">
                            <div className="table-wrapper">
                                <table className="table">                     
                                <tbody>
                                    <tr>
                                    <td className='p-12'>Месячная плата за 1 ТС, кол-во 1-15</td>
                                    <td className='p-12'>От 23.000 ₽ с НДС</td>
                                    </tr>
                                    <tr>
                                    <td className='p-12'>Месячная плата за 1 ТС, кол-во 16-30</td>
                                    <td className='p-12'>От 20.000 ₽ с НДС</td>
                                    </tr>
                                    <tr>
                                    <td className='p-12'>Месячная плата за 1 ТС, кол-во 31-50</td>
                                    <td className='p-12'>От 18.500 ₽ с НДС</td>
                                    </tr>
                                    <tr>
                                    <td className='p-12'>Месячная плата за 1 ТС, кол-во от 51</td>
                                    <td className='p-12'>От 17.000 ₽ с НДС</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="mt-16">
                            <p className="t-xs">ТС - транспортное средство</p>
                            <p className="t-xs mt-16">
                                Примечание: в стоимость лицензии не входят услуги разработчика по интеграции, настройке 
                                тестированию программного продукта и обучению персонала.
                            </p>
                        </div>
                    </div>
                </section> 

                {/* <div className="d-fl fl-col ai-c px-16 px-xs-24 px-lg-40">  
                  <section id="driver-manual" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll services__text">     
                      <div className="about container-fixed mt-24">
                              <h2 className="h2 t-c fade-in-top-small a-dr-06 js-scroll ">Краткое руководство по использованию <br /> «Челнок водитель»</h2>
                               <ol>
                                  <li>
                                      <h3 className="manual-title">Начало работы</h3>
                                      <p className=" mt-24">
                                          Для того, чтобы начать использовать приложение "Челнок-Водитель", после выбора соответствующего города введите 
                                          Ваш номер мобильного телефона (на рис.1), привязанный к Вашей учетной записи. 
                                          На номер должен прийти 4-значный SMS-код, водитель вводит его (на рис.2).
                                      </p>
                                      <div className="d-fl fd-c jc-c ai-c">
                                        <figure>
                                            <img className="img" src="../img/gallery/driver-manual/phone-input.jpg" alt="Ввод телефона"  />
                                            <figcaption className="t-c">Рисунок 1. Ввод номера телефона</figcaption>
                                        </figure>
                                      </div>
                                      <div className="d-fl fd-c jc-c ai-c">
                                        <figure>
                                            <img className="img" src="../img/gallery/driver-manual/sms-input.jpg" alt="Ввод кода из смс" />
                                            <figcaption className="t-c">Рисунок 2. Ввод СМС-кода</figcaption>
                                        </figure>
                                      </div>
                                      <p className="mt-8">
                                          После успешного входа в приложение, слева будет отображаться актуальная информация о начале смены и конце,
                                          номере борта, также информация о водителе.
                                      </p>
                                      <p className="mt-8">
                                          Кнопка выхода на линию находится на самом видном месте – слева главного экрана.
                                      </p>
                                      <p className="mt-8">
                                          Активировать кнопку «Начать смену» можно за 15 минут до начала смены, а также в течение смены, указанной в приложении.
                                          Также активировать кнопку «Начать смену» (на рис.3) можно только в радиусе Депо (т.е. Автопарка).
                                      </p>

                                      <div className="d-fl fd-c jc-c ai-c mt-12">
                                        <figure>
                                            <img className="img" src="../img/gallery/driver-manual/shift-start.jpg" alt="Начало смены" />
                                            <figcaption className="t-c">Рисунок 3. Начало смены</figcaption>
                                        </figure>
                                      </div>

                                      <p className="mt-8">
                                        После активации кнопки «Начать смену» водителя будет вести на ближайший свободный
                                        терминал. Терминал – это место сосредоточения или точка ожидания транспортного
                                        средства в рамках ПДД во время активной смены, когда нет активных заказов в цепочке.
                                        (на рис.4)
                                      </p>

                                      <div className="d-fl fd-c jc-c ai-c mt-12">
                                        <figure>
                                            <img className="img" src="../img/gallery/driver-manual/await-point.jpg" alt="Точка ожидания (Терминал)" />
                                            <figcaption className="t-c">Рисунок 4. Точка ожидания (Терминал)</figcaption>
                                        </figure>
                                      </div>

                                      <p className="mt-8">
                                        Если заказов в цепочке нет, водителю следует ожидать их поступления в данном месте.
                                        Всего терминалов, расположенных в Челнах, несколько. Алгоритм сам его выбирает
                                      </p>
                                  </li>
                                  <li>
                                    <h3 className="manual-title">Совершение заказов (посадка/высадка пассажиров)</h3>
                                    <p className="mt-8"> 
                                        После поступления заказа водителю приходит соответствующее звуковое уведомление,
                                        будет строиться навигация до точки посадки. С помощью смахивания вверх можно будет
                                        ознакомиться с полной цепочкой заказов (на рис.5), конечной точкой будет всегда
                                        Терминал (точка ожидания, депо или место перерыва).
                                    </p>
                                    <div className="d-fl fd-c jc-c ai-c">
                                        <figure>
                                            <img className="img" src="../img/gallery/driver-manual/chain-orders.jpg" alt="Цепочка заказов" />
                                            <figcaption className="t-c">Рисунок 5. Цепочка заказов</figcaption>
                                        </figure>
                                    </div>

                                    <p className="mt-8">
                                        Следует выделять 2 типа остановок: точка посадки (зеленым цветом) и точка высадки (красным цветом).
                                    </p>

                                    <div className="d-fl fd-c jc-c ai-c">
                                        <figure>
                                            <img className="img" src="../img/gallery/driver-manual/road-to-board.jpg" alt="Маршрут до точки посадки" />
                                            <figcaption className="t-c">Рисунок 6. Маршрут до точки посадки</figcaption>
                                        </figure>
                                    </div>

                                    <p className="mt-8">
                                        По достижению точки посадки снизу активизируется кнопка «На месте» (на рис.7).
                                    </p>

                                    <div className="d-fl fd-c jc-c ai-c">
                                        <figure>
                                            <img className="img" src="../img/gallery/driver-manual/road-to-position.jpg" alt='Кнопка "На месте"' />
                                            <figcaption className="t-c">Рисунок 7. Кнопка "На месте"</figcaption>
                                        </figure>
                                    </div>

                                    <p className="mt-8">
                                        По ее нажатию откроется лист ожидания пассажиров с соответствующим таймером ожидания (на рис.8). 
                                    </p>

                                    <div className="d-fl fd-c jc-c ai-c">
                                        <figure>
                                            <img className="img" src="../img/gallery/driver-manual/await-taimer.jpg" alt="Таймер ожидания пассажиров" />
                                            <figcaption className="t-c">Рисунок 8. Таймер ожидания пассажиров</figcaption>
                                        </figure>
                                    </div>

                                    <p className="mt-8">
                                        При посадке пользователя с помощью QR-кода кнопка «на борту» активируется автоматически. 
                                        Если у пользователя нет возможности QR-код, он должен пройти идентификацию по 6 последним цифрам своего телефона. 
                                    </p>

                                    <p className="mt-8">
                                        После идентификации по номеру телефона водитель нажимает на кнопку «на борту», которая срабатывает 5 секунд. 
                                    </p>

                                    <p className="mt-8">
                                        Если пассажир задерживается, водитель ждет в течение того времени, которое указано. Чаще всего оно равняется 2 минутам. 
                                    </p>

                                    <p className="mt-8">
                                        По случаю неявки пассажира по истечению таймера водитель наживает на кнопку «Не пришел» (на рис.9)
                                    </p>

                                    <div className="d-fl fd-c jc-c ai-c">
                                        <figure>
                                            <img className="img" src="../img/gallery/driver-manual/start-order.jpg" alt='Кнопка "В путь"' />
                                            <figcaption className="t-c">Рисунок 9. Кнопка "В путь"</figcaption>
                                        </figure>
                                    </div>

                                    <p className="mt-8">
                                        Далее на кнопку «в путь», начинает строиться маршрут до следующей точки в цепочке заказов. (на рис.10)
                                    </p>

                                    <div className="d-fl fd-c jc-c ai-c">
                                        <figure>
                                            <img className="img" src="../img/gallery/driver-manual/navigate-route.jpg" alt='Навигация до точки высадки' />
                                            <figcaption className="t-c">Рисунок 10. Навигация до точки высадки</figcaption>
                                        </figure>
                                    </div>

                                    <p className="mt-8">
                                        По достижению точки высадки активируется кнопка «На месте», по ее нажатию открывается лист пассажиров, 
                                        кто должен выходить. (на рис.11)
                                    </p>

                                    <div className="d-fl fd-c jc-c ai-c">
                                        <figure>
                                            <img className="img" src="../img/gallery/driver-manual/list-passanger.jpg" alt='Лист пассажиров на выход' />
                                            <figcaption className="t-c">Рисунок 11. Лист пассажиров на выход</figcaption>
                                        </figure>
                                    </div>

                                    <p className="mt-8">
                                        По успешному завершению заказа водитель нажимает «В путь», начинает строиться навигация до следующего заказа.
                                    </p>
                                  </li>

                                  <li>
                                        <h3 className="manual-title">Перерывы</h3>
                                        <p className="mt-8">
                                            В течение смены водителю предусмотрены регламентированные перерывы (30, 45, 60 минут или 4 часа в зависимости от смены). 
                                            Примерно за полчаса до его начала в цепочке заказов будет отображаться терминал перерыва. По завершению оставшихся заказов 
                                            до терминала перерыва начнет строиться навигация. (на рис.12).
                                        </p>

                                        <div className="d-fl fd-c jc-c ai-c">
                                            <figure>
                                                <img className="img" src="../img/gallery/driver-manual/navigate-to-terminal.jpg" alt="Навигация до терминала" />
                                                <figcaption className="t-c">Рисунок 12. Навигация до терминала</figcaption>
                                            </figure>
                                        </div>

                                        <p className="mt-8">
                                            По достижению терминала перерыва водитель нажимает на кнопку «Начать перерыв». 
                                            Если точка перерыва не совпадает с терминалом, водитель может начать перерыв самостоятельно в другом месте, 
                                            зажав кнопку «Начать перерыв» слева в меню
                                        </p>
                                  </li>
                                  <li>
                                    <h3 className="manual-title">Завершение смены</h3>

                                    <p className="mt-8">
                                        За полчаса до конца завершения смены в цепочке заказов будет отображаться Депо (автопарк).
                                        По завершению оставшихся заказов начнет строиться навигация. По достижению за 10 минут до завершенияКарта поделена на светл
                                        смены активируется данная возможность.(На рис. 13)
                                    </p>

                                    <div className="d-fl fd-c jc-c ai-c">
                                            <figure>
                                                <img className="img" src="../img/gallery/driver-manual/end-shift.jpg" alt='Кнопка "Завершить смену"' />
                                                <figcaption className="t-c">Рисунок 13. Кнопка "Завершить смену"</figcaption>
                                            </figure>
                                        </div>
                                  </li>
                               </ol>               
                      </div>                           
                  </section>      
                  </div>  



                  <div className="d-fl fl-col ai-c px-16 px-xs-24 px-lg-40">  
                  <section id="dispatcher-manual" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll services__text">     
                      <div className="dispatcher-manual container-fixed mt-24">
                            <h2 className="h2 t-c fade-in-top-small a-dr-06 js-scroll ">Краткое руководство по использованию <br /> «Челнок диспечер</h2>
                            <h3 className="manual-title t-c mt-24">Оглавление</h3>
                            <ul className="mt-24">
                                <li><a class="menu-container__link menu" href="#dispatcher-manual-general-information">Общие сведения о сервисе</a> </li>
                                <li><a class="menu-container__link menu" href="#dispatcher-manual-advantages">Преимущества работы сервиса</a></li>
                                <li><a class="menu-container__link menu" href="#dispatcher-manual-command">Обязанности диспетчерской команды</a> </li>
                                <li><a class="menu-container__link menu" href="#dispatcher-manual-authorization">Авторизация для начала работы</a> </li>
                                <li><a class="menu-container__link menu" href="#dispatcher-manual-workplace">Ваше рабочее пространство</a></li>
                                <li><a class="menu-container__link menu" href="#dispatcher-manual-routes">Поездки</a></li>
                                <li><a class="menu-container__link menu" href="#dispatcher-manual-points-await">Точки ожидания водителей между поездками</a></li>
                                <li><a class="menu-container__link menu" href="#dispatcher-manual-work">Работа с приложением «Челнок»</a></li>
                                <li><a class="menu-container__link menu" href="#dispatcher-manual-shifts">Планировщик смен</a></li>
                                <li><a class="menu-container__link menu" href="#dispatcher-manual-finish-shifts">Окончание смены</a></li>
                                <li><a class="menu-container__link menu" href="#dispatcher-manual-fqa">Часто задаваемые вопросы</a></li>
                            </ul>
                        <section id="dispatcher-manual-general-information" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll services__text">
                                <div className="dispatcher-manual container-fixed mt-24">
                                    <h3 className="manual-title t-c mt-24">Общие сведения о сервисе</h3>

                            <div className="table-wrapper mt-24">
                                <table className="table">                     
                                <tbody>
                                    <tr>
                                        <td className='p-12'><b>Вопрос</b></td>
                                        <td className='p-12 licence-column'>
                                         <b>Ответ</b> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Что такое автобусные перевозки по требованию и как это работает?</td>
                                        <td className='p-12'>
                                            <p> <b>Наш сервис – новый вид транспорта, он похож</b> одновременно и на автобусные перевозки, и на такси</p>
                                            <p className="mt-4"><b>Чем похоже на такси?</b> Микроавтобус направляется к каждому конкретному пассажиру, сделавшему заказ</p>
                                            <p className="mt-4">
                                                <b>Чем похоже на автобус?</b>
                                                <ul className="mt-8">
                                                    <li>
                                                        Маршруты могут быть групповыми: микроавтобус по очереди собирает несколько пассажиров и развозит 
                                                        их по точкам назначения. 
                                                    </li>
                                                    <li>
                                                        Посадка пассажира – не у точки заказа (как в такси),
                                                        а у ближайшей разрешенной точки посадки (больше,
                                                        чем остановок автобуса, но не в любом месте)
                                                    </li>
                                                    <li>Доехать можно не в любую точку, а в те точки, которые определены правилами сервиса</li>
                                                </ul>
                                                </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Как определяются маршруты?</td>
                                        <td className='p-12'><b>Специальный алгоритм строит их в автоматическом режиме</b>  на основе всех заказов пассажиров</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Как пассажир может сделать заказ?</td>
                                        <td className='p-12'> <b>Заказать сервис можно только через мобильный телефон</b>,
                                         для этого необходимо установить приложение «Челнок»</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Где пассажир садится или выходит из ТС?</td>
                                        <td className='p-12'>
                                            <ul>
                                                <li><b>Приложение само определяет ближайшую для вас точку</b> посадки или высадки</li>
                                                <li><b>Возможны два типа точек</b> посадки или высадки: 
                                                    <ul className="mt-8 mb-8 ml-24">
                                                        <li><b>Автобусные остановки:</b> задействуются все автобусные остановки в зоне действия сервиса</li>
                                                        <li><b>«Виртуальные остановки»:</b> дополнительные точки посадки в местах, 
                                                        где остановка разрешена ПДД. Виртуальные остановки важны там, где у пассажиров 
                                                        нет возможности дойти до стандартных автобусных остановок за 5-10 минут</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <b>В отдельных случаях пеший путь до точки посадки может превышать 10 минут.</b>
                                                    Это может произойти, если дорога не позволяет безопасно посадить и высадить 
                                                    пассажиров или если запрещен проезд на территорию населенного пункта
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Что происходит, если пассажир захотел выйти не на своей остановке?</td>
                                        <td className='p-12'>
                                            <ul>
                                                <li>
                                                    <b>Сервис НЕ предполагает возможность</b> выхода пассажира на остановке 
                                                    <b>по его желанию</b> – только в точках, выбранных при заказе
                                                </li>
                                                <li>
                                                    В случае <b>если пассажир будет настаивать</b>, водитель
                                                    может высадить пассажира на <b>ближайшей доступной виртуальной остановке 
                                                    по указанию диспетчера</b> 
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Когда по времени суток работает сервис?</td>
                                        <td className='p-12'>
                                            <ul>
                                                <li>
                                                    Время работы сервиса –<b> с 6:00 до 23:30</b>
                                                </li>
                                                <li>
                                                    <b>Последний заказ – в 23:00</b>, после этого кнопки для оформления заказа в приложении станут неактивны
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Сколько стоит поездка? И как ее может оплатить пассажир?</td>
                                        <td className='p-12'>
                                            <b>51 рубль</b> – стоимость поездки, столько же стоит пассажирам разовая 
                                            поездка на автобусе при оплате банковской картой 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Предоставляются ли льготы?</td>
                                        <td className='p-12'>
                                            <b>Нет</b>, в рамках этого проекта <b>льготы не предусмотрены</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Как определяется, что сел пассажир, который сделал заказ?</td>
                                        <td className='p-12'>
                                            <ul>
                                                <li className="mt-4">
                                                    Пассажир проходит <b>идентификацию по QR-коду</b> при посадке
                                                    (считывает своим телефоном распечатанный QR-код, размещенный в салоне ТС при входе)
                                                </li>
                                                <li className="mt-4">
                                                    Когда пассажир просканировал QR-код, 
                                                    <b> у водителя в Приложении появляется уведомление о посадке</b> 
                                                </li>
                                                <li className="mt-4">
                                                    <b>Если QR-код не считывается</b> или возникли другие 
                                                    проблемы, <b>водитель может</b> отметить посадку пассажира 
                                                    вручную, используя для <b>идентификации 6 последних цифр номера телефона пассажира</b>
                                                     которые он указал в Приложении
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>
                                            Что будет если пассажир не пришёл на место посадки? Или захотел отменить поездку?
                                        </td>
                                        <td className='p-12'>
                                        <b>ТС ожидает пассажира на месте посадки не более 2-х минут.</b> 
                                        После этого водитель имеет право уехать для выполнения других заказов / продолжения маршрута, а 
                                        <b> стоимость поездки будет удержана с пассажира</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Может ли пассажир сделать предварительное бронирование поездки?</td>
                                        <td className='p-12'>
                                            <b>Нет</b>, бронировать заказ на определенное время нельзя
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Может ли пассажир сделать заказ на нескольких человек?</td>
                                        <td className='p-12'>
                                            <b>Да</b>, можно заказать и оплатить поездку сразу на несколько пассажиров (групповая поездка)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Какие ТС будут использоваться для сервиса?</td>
                                        <td className='p-12'>
                                            Будут использоваться автобусы ГАЗель на 16 человек
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            <p>
                                Ниже приводится часть основной карты сервиса, как она выглядит в
                                Приложении для пассажира (рис. 1) и диспетчера (рис. 2). Карта поделена на
                                светлые и темные участки:
                            </p>
                            <ul>
                                <li className="ml-24 mt-8">Темные участки — это места, недоступные для посадки/высадки пассажиров.</li>
                                <li className="ml-24 mt-8">Светлые участки – это места с активной зоной, в которой пассажир может сделать заказ автобуса. 
                                    <br />

                                </li>

                            </ul>
                            Услуги в затемненных зонах не предоставляются по следующим причинам:
                                    <ul>
                                        <li className="ml-24 mt-4">Данный участок выходит за границы поселений, определенных в качестве зон реализации проекта;</li>
                                        <li className="ml-24 mt-4">Транспортные узлы находятся не дальше, чем в 1 км (предполагается, что можно дойти пешком);</li>
                                        <li className="ml-24 mt-4">Предотвращение излишней конкуренции с регулярными маршрутами НГПТ в зоне с их высокой плотностью</li>
                                    </ul>
                            <div className="d-fl fd-c jc-c ai-c">
                                        <figure>
                                            <img className="img" src="../img/gallery/dispatcher-manual/passenger-main-page.jpg" alt="Экран пассажира" />
                                            <figcaption className="t-c">Рисунок 1. Экран пассажира</figcaption>
                                        </figure>
                            </div>
                            <div className="d-fl fd-c jc-c ai-c">
                                        <figure>
                                            <img className="img" src="../img/gallery/dispatcher-manual/dispatcher-main-page.jpg" alt="Экран диспетчера" />
                                            <figcaption className="t-c">Рисунок 2. Экран диспетчера</figcaption>
                                        </figure>
                            </div>
                            <p className="mt-12">
                                В рамках сервиса, вместо движения по постоянному маршруту, водители следуют указаниям навигации
                                водительского приложения (аналогичные указаниям любого другого навигатора наподобие Google
                                или Яндекс Навигатор).
                            </p>
                        </div>
                    </section>
                    <section id="dispatcher-manual-advantages" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll services__text">
                        <div className="dispatcher-manual-advantages container-fixed mt-24">
                            <h3 className="manual-title t-c mt-24">Преимущества работы сервиса</h3>
                            <p className="mt-24">Основные преимущества сервиса автобусных перевозок по требованию:</p>
                            <ul className="ml-24 mt-12">
                                <li>
                                    <b>Качественный наземный транспорт для людей в удаленных районах</b> – микроавтобус может приехать к пассажиру в места, где нельзя обеспечить маршрут полноразмерного автобуса:
                                    <ul className="ml-48 mt-8">
                                        <li>Может проехать по более узким дорогам и останавливаться в местах, где нет оборудованных остановок</li>
                                        <li>Работает в населенных пунктах, где пассажиров недостаточно, чтобы полноразмерные автобусы не были пустыми</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Удобство посадки и высадки:</b>
                                    <ul className="ml-48 mt-8">
                                        <li>Возможность заказать микроавтобус в мобильном приложении, отслеживать его движение в реальном времени и подойти на посадку прямо ко времени прибытия</li>
                                        <li>При любом заказе пеший путь до точки посадки или высадки не будет превышать 5-10 минут, за исключением закрытых населенных пунктов и дорог, где посадка и высадка пассажиров небезопасна</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>Сравнительно короткая длина поездки:</b> микроавтобус не делает лишних остановок, кроме как для подсадки пассажиров, определенных на данный маршрут. Когда автобус уже в пути, новые заказы на него принимаются только если для текущих пассажиров время их поездки изменится не больше, чем указано в их приложении
                                </li>
                                <li>
                                    <b>Больший комфорт в пути:</b>  новые Mercedes Sprinter, где каждому пассажиру гарантируется сидячее место
                                </li>
                                <li>
                                    <b>Сравнительно низкая стоимость поездки:</b>  51 рубль – стоимость поездки на регулярных автобусных маршрутах, существенно ниже цены такси.
                                </li>
                            </ul>
                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/passenger-route.jpg" alt="Схема поездки пассажира" />
                                    <figcaption className="t-c">Рисунок 3. Схема поездки пассажира</figcaption>
                                </figure>
                            </div>
                        </div>
                    </section> 

                    <section id="dispatcher-manual-command" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll services__text">
                        <div className="dispatcher-manual-command container-fixed mt-24">
                            <h3 className="manual-title t-c mt-24">Обязанности диспетчерской команды</h3>
                            <ul className="ml-24 mt-12">
                                <li className="mt-8">Операционная деятельность и поддержка в режиме реального времени: управление операционной деятельностью в режиме реального времени и оказание помощи водителям. </li>
                                <li className="mt-8">Оптимизация операционной деятельности: улучшение качества карт и разметки «виртуальных автобусных остановок». </li>
                                <li className="mt-8">Управление предложением и оптимизация системы: уравновешивание спроса            и предложения с помощью планирования и регулирования параметров системы. </li>
                                <li className="mt-8"> Управление водителями: вовлечение и управление эффективностью водителей.</li>
                            </ul>
                        </div>
                    </section>

                    <section id="dispatcher-manual-authorization" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll services__text">
                        <div className="dispatcher-authorization container-fixed mt-24">
                            <h3 className="manual-title t-c mt-24">Авторизация для начала работы</h3>
                            <p className="mt-24">
                                Сайт <a href="https://msk.mt.dpkapp.ru/" rel="noreferrer" target="_blank">Челнок (dpkapp.ru)</a>  – это ваш главный инструмент для обеспечения бесперебойной работы сервиса. С его помощью можно оказывать поддержку водителям и управлять сервисом в режиме реального времени.
                            </p>
                            <p>
                                Для авторизации в системе диспетчерского контроля вам необходимо ввести ваш логин и пароль (рис. 4).
                            </p>
                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/authorization.jpg" alt="Страница авторизации" />
                                    <figcaption className="t-c">Рисунок 4. Страница авторизации</figcaption>
                                </figure>
                            </div>
                        </div>
                    </section> 

                    <section id="dispatcher-manual-workplace" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll services__text">
                        <div className="dispatcher-workplace container-fixed mt-24">
                            <h3 className="manual-title t-c mt-24">Ваше рабочее пространство</h3>
                            <p className="mt-24">
                                В разделе «Карта» можно отслеживать перемещение бортов (рис. 5). Слева наверху можно отслеживать ТС по статусам (рис. 6).
                            </p>
                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/work-main-page.jpg" alt="Главная страница диспечера" />
                                    <figcaption className="t-c">Рисунок 5. Главная страница диспечера</figcaption>
                                </figure>
                            </div>
                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/vehicle-status.jpg" alt="Статусы ТС" />
                                    <figcaption className="t-c">Рисунок 6. Статусы ТС</figcaption>
                                </figure>
                            </div>
                            <p>
                                В поисковой строке сверху в слева возможен поиск по: <br />
                                    <span className="ml-24">- ID заказа;</span><br />
                                    <span className="ml-24">- номеру борта;</span><br />
                                    <span className="ml-24">- имени или фамилии водителя (рис. 7).</span>
                            </p>

                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/search-main.jpg" alt="Поисковая строка" />
                                    <figcaption className="t-c">Рисунок 7. Поисковая строка</figcaption>
                                </figure>
                            </div>

                            <p>
                                В разделе «Заказы» отображается список поступающих заказов в режиме реального времени (рис. 8).
                            </p>

                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/orders-page.jpg" alt="Страница заказов" />
                                    <figcaption className="t-c">Рисунок 8. Страница заказов</figcaption>
                                </figure>
                            </div>

                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/orders-filter.jpg" alt="Поиск по заказам" />
                                    <figcaption className="t-c">Рисунок 9. Поиск по заказам</figcaption>
                                </figure>
                            </div>

                            <p>
                                Раздел «Карта» используется для мониторинга движения и статуса ТС и текущих поездок. Ниже указаны обозначения статусов ТС:
                            </p>

                            <div className="d-fl ai-c fl-md-row ml-24 mt-12">
                                <img className="img slider-image" src="../img/gallery/dispatcher-manual/active-state.jpg" alt="Статус активен" />
                                <p className="ml-4">- Активен. Водитель вошел в смену, и заказы могут поступать на данный борт.</p>
                            </div>
                            <div className="d-fl ai-c fl-md-row ml-24 mt-12">
                                <img className="img slider-image" src="../img/gallery/dispatcher-manual/inactive-state.jpg" alt="Статус  неактивен" />
                                <p className="ml-4">- Не в сети. Данный статус борта не является нормой. Есть 2 причины нахождения борта в данном статусе: водитель вышел из приложения либо сеть не доступна на данном участке дороги. Нужно обратить внимание водителя на его статус, т.к. в данный момент заказы не поступают, а если заказы поступили, то он их не видит. Можно проверить время последней активности водителя в приложении. Для этого нужно кликнуть мышкой на борт, и в строке, где указано время, можно увидеть последнее время нахождения водителя в приложении.</p>
                            </div>
                            <img className="img w-20 ml-12 mt-12" src="../img/gallery/dispatcher-manual/inactive-card.jpg" alt="Карточка в приложении" />

                            <div className="d-fl ai-c fl-md-row ml-24 mt-12">
                                <img className="img slider-image" src="../img/gallery/dispatcher-manual/breack-state.jpg" alt="Статус  на перерыв" />
                                <p className="ml-4">- Едет на перерыв или завершает смену. Программа в этом статусе скрывает борт от новых заказов, в это время водитель может завершать предыдущие заказы.</p>
                            </div>

                            <div className="d-fl ai-c fl-md-row ml-24 mt-12">
                                <img className="img slider-image" src="../img/gallery/dispatcher-manual/close-shift.jpg" alt="Статус завершенной смены" />
                                <p className="ml-4">- Завершил смену. Новые заказы в этом статусе перестают поступать. Данный статус появляется после того, как у водителя заканчивается смена, заложенная в программе. Завершить смену за водителя может диспетчер в критичных случаях (произошло ДТП, водителю нужно ехать на ремонт и т.д.).</p>
                            </div> 

                            <div className="d-fl ai-c fl-md-row ml-24 mt-12">
                                <img className="img slider-image" src="../img/gallery/dispatcher-manual/on-break.jpg" alt="Статус на перерыве" />
                                <p className="ml-4"> - На перерыве. Водитель нажал кнопку «Начать перерыв» после завершения всех предыдущих заказов, после чего у него появляется таймер на заложенное время перерыва.</p>
                            </div> 
                        </div>
                    </section>

                    <section id="dispatcher-manual-routes" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll services__text">
                        <div className="dispatcher-routes container-fixed mt-24">
                            <h3 className="manual-title t-c mt-24">Поездки</h3>
                            <p className="mt-24">
                                Если кликнуть на нужный борт на карте или найти его через поисковую систему, выйдет полный список заказов (рис. 10). Обозначения можно посмотреть на рис. 10.
                            </p>
                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/routes.jpg" alt="Маршрут поездки" />
                                    <figcaption className="t-c">Рисунок 10. Маршрут поездки</figcaption>
                                </figure>
                            </div>
                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/route-details.jpg" alt="Расшифровка карточки поездки" />
                                    <figcaption className="t-c">Рисунок 11. Расшифровка карточки поездки</figcaption>
                                </figure>
                            </div>
                        </div>
                    </section>

                    <section id="dispatcher-manual-points-await" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll services__text">
                        <div className="dispatcher-points-await container-fixed mt-24">
                            <h3 className="manual-title t-c mt-24">Точки ожидания водителей между поездками</h3>
                            <p className="mt-24">
                                Точки ожидания — это точки, куда водители направляются приложением, если у них нет текущих заданий (забрать пассажира, высадить пассажира, уйти на перерыв, закончить смену). В этих точках водители могут запарковаться пока ожидают следующего задания.
                            </p>
                            <p>
                                Между заказами водитель должен следовать указаниям навигатора, проехав к определенному терминалу (рис. 12).
                            </p>
                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/rout-to-terminal.jpg" alt="Маршрут до терминала" />
                                    <figcaption className="t-c">Рисунок 12. Маршрут до терминала</figcaption>
                                </figure>
                            </div>
                        </div>
                    </section>

                    <section id="dispatcher-manual-work" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll services__text">
                        <div className="dispatcher-work container-fixed mt-24">
                            <h3 className="manual-title t-c mt-24">Работа с приложением «Челнок»</h3>
                            <p className="mt-24">
                                На главном экране в приложении «Челнок» при нажатии на кнопку «Меню»
                                <span className="mr-8 ml-8">
                                    <img className="img menu-button" src="../img/gallery/dispatcher-manual/menu-button.jpg" alt="Кнопка меню" /> 
                                </span>
                            у пассажира появляется кнопка «Поддержка» (рис. 13), при нажатии на которую появляется выбор «Написать на почту» и «Написать в чат» (рис. 14). При нажатии на эти кнопки у пользователя автоматически открывается сообщение в электронной почте либо социальной сети, где он описывает проблему, отправляет, после чего сообщение попадает к диспетчеру.
                            </p>
                               
                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/passanger-menu.jpg" alt="Меню приложения" />
                                    <figcaption className="t-c">Рисунок 13. Меню приложения</figcaption>
                                </figure>
                            </div>

                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/support.jpg" alt="Выбор способа обращения в поддержку" />
                                    <figcaption className="t-c">Рисунок 14. Выбор способа обращения в поддержку</figcaption>
                                </figure>
                            </div>
                        </div>
                    </section>

                    <section id="dispatcher-manual-shifts" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll services__text">
                        <div className="dispatcher-shifts container-fixed mt-24">
                            <h3 className="manual-title t-c mt-24">Планировщик смен</h3>
                            <p className="mt-24">
                                Планировщик смен – это система управления и планирования смен. Её функционал включает в себя:
                            </p>
                            <ul>
                                <li className="mt-8">Планирование смен для водителей и транспортных средств </li>
                                <li className="mt-8">Просмотр плана и внесение изменений </li>
                                <li className="mt-8">Мониторинг выполнения плана в реальном времени </li>
                                <li className="mt-8">Автоматическое выполнение плана (предписание действий для водителей)</li>
                                <li className="mt-8">Анализ производительности и эффективности (сравнение плановых и фактических показателей эффективности)</li>
                            </ul>
                            <p className="mt-12">
                                Ниже представлен раздел «Смены», в котором необходимо запланировать время работы и перерывов водителей (рис. 15).
                            </p>
                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/shifts.jpg" alt="Раздел «Смены»" />
                                    <figcaption className="t-c">Рисунок 15. Раздел «Смены»</figcaption>
                                </figure>
                            </div>
                            <p className="mt-12">
                                Формирование смены для водителя выглядит следующим образом (рис. 16). После внесения всех данных необходимо нажать кнопку «Сохранить».
                            </p>
                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/add-shift.jpg" alt="Формирование смены" />
                                    <figcaption className="t-c">Рисунок 16. Формирование смены</figcaption>
                                </figure>
                            </div>
                            <p className="mt-12">
                                Если есть необходимость найти данные по смене водителя, можно воспользоваться фильтром, выбрав номер борта или фамилию водителя, задав при этом необходимый период времени (рис. 17).
                            </p>
                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/search-shift.jpg" alt="Поиск смены" />
                                    <figcaption className="t-c">Рисунок 17. Поиск смены</figcaption>
                                </figure>
                            </div>
                            <p className="mt-12">
                                Если у водителя возникли технические трудности с началом или окончанием смены, с уходом на перерыв или выходом из него, в разделе «Смены» можно за него выбрать данные действия, нажав соответствующую кнопку (рис. 18):
                                <ul className="mt-8 ml-24">
                                    <li className="mt-4">Начать смену</li>
                                    <li className="mt-4">Завершить смену</li>
                                    <li className="mt-4">Начать перерыв</li>
                                    <li className="mt-4">Завершить перерыв</li>
                                </ul>
                            </p>
                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/break-shift.jpg" alt="Управление перерывом смены" />
                                    <figcaption className="t-c">Рисунок 18. Управление перерывом смены</figcaption>
                                </figure>
                            </div>
                        </div>
                    </section>

                    <section id="dispatcher-manual-finish-shifts" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll services__text">
                        <div className="dispatcher-finish-shifts container-fixed mt-24">
                            <h3 className="manual-title t-c mt-24">Окончание смены</h3>
                            <p className="mt-24">
                                Для окончания смены диспетчером в основном меню, слева в нижнем углу необходимо нажать кнопку «Выйти» (рис. 19).
                            </p>
                               
                            <div className="d-fl fd-c jc-c ai-c">
                                <figure>
                                    <img className="img" src="../img/gallery/dispatcher-manual/exit.jpg" alt="Выход из приложения" />
                                    <figcaption className="t-c">Рисунок 19. Выход из приложения</figcaption>
                                </figure>
                            </div>
                        </div>
                    </section>      

                    <section id="dispatcher-manual-fqa" className="section section--white mt-24 fade-in-bottom a-dr-05 js-scroll services__text">
                        <div className="dispatcher-fqa container-fixed mt-24">
                            <h3 className="manual-title t-c mt-24">Часто задаваемые вопросы</h3>
                            <h3 className="manual-title t-c mt-24">Обращения водителя</h3>
                            <div className="table-wrapper mt-24">
                                <table className="table">                     
                                <tbody>
                                    <tr>
                                        <td className='p-12'><b>Вопрос</b></td>
                                        <td className='p-12 licence-column'>
                                         <b>Ответ</b> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Приложение для водителя не отвечает (зависло)</td>
                                        <td className='p-12'>Предложить водителю следующие решения (последовательно): <br />
                                            1. перезагрузить приложение. <br />
                                            2. перезагрузить планшет, переместить ТС в другую зону (при отсутствии связи на планшете после перезагрузки), <br />
                                            3. переустановить приложение<div className="br"></div>
                                            В случае, если предложенные решения не помогают - связаться с начальником автоколонны и предупредить о замене ТС на резервный, направить информацию для решения проблемы диспетчеру, который связывается с тех. поддержкой.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Нет сигнала в Приложении для водителя (но не зависло)</td>
                                        <td className='p-12'>
                                            Предложить водителю переместить ТС в другую локацию. Перезагрузить планшет. Проверить баланс сим-карты.
                                            Если предложенное решение не помогает - связаться с начальником автоколонны и предупредить о замене ТС на резервный, направить информацию для решения проблемы диспетчеру, который связывается с тех. поддержкой.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Не подготовлено ТС</td>
                                        <td className='p-12'> 
                                            Позвонить водителю и уточнить с чем возникла проблема, коммуникация проблемы начальнику автоколонны для ускорения ее решения, корректировка расписания водителя при необходимости / уведомление главного диспетчера о необходимости корректировки расписания
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Сломался планшет</td>
                                        <td className='p-12'>
                                            Корректировка расписания водителя с обновлением конца смены раньше для отправления в парк / уведомление главного диспетчера о необходимости корректировки расписания смены
                                            Уточнение наличия резервного ТС в парке для отправления водителя на линию (корректировка расписания водителя - создание новой смены / уведомление главного диспетчера о необходимости создания новой смены)
                                            В случае отсутствия резервных ТС предупреждение начальника колонны о необходимости подготовки резервного планшета для замены на ТС
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>В ТС пытается сесть лишний пассажир (нет свободных мест)</td>
                                        <td className='p-12'>
                                            Попросить водителя сохранять спокойствие и уточнить пассажиров, кто ранее сел в ТС без билета. При наличии ответа попросить пассажира выйти из транспортного средства. Если пассажиры молчат, то с помощью водителя объяснить пассажиру, что в данной ситуации лучше всего будет пассажиру отменить поездку и вызвать ТС снова. 
                                            Если QR-код был отсканирован, то сделать форсированную высадку.
                                            Если QR не был отсканирован - предложить пассажиру или отменить поездку (холдирование средств пассажира будет отменено автоматически) или перебросить ее на другое ТС (функция временно недоступна, необходимо отменить заказы).
                                            Далее в любом случае заблокировать ТС для приема заказов до завершения поездки. Потом снова сделать ТС активным
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>В ТС пытается сесть пассажир без билета (за исключением несовершеннолетних детей)</td>
                                        <td className='p-12'>
                                            Попросить водителя сохранять спокойствие и сообщить безбилетнику, что водитель имеет право не продолжать движение при наличии безбилетного пассажира в салоне и предложить ему вызвать ТС через мобильное приложение пассажира. При агрессивном поведении пассажира предупредить о вызове правоохранительных органов. В случае агрессивного поведения пассажира и угроз применить физическую силу по отношению к водителю – вызвать полицию.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Конфликтная ситуация с пассажирами / пассажир ведет себя неадекватно</td>
                                        <td className='p-12'>
                                            Попросить водителя сохранять спокойствие - в случае возникновения вопросов по работе сервиса предложить пассажирам прочитать правила его пользования (на сайте, в Приложении, по QR коду, размещенному в ТС). При агрессивном поведении пассажира предупредить о вызове правоохранительных органах. В случае агрессивного поведения пассажира и угроз применить физическую силу по отношению к водителю – вызвать полицию.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>У водителя заканчивается топливо в ТС</td>
                                        <td className='p-12'>
                                            Документирование обращения водителя и передача информации начальнику колонны для выяснения причин. 
                                            Перевод ТС в статус неактивное для завершения текущего заказа и неполучения новых. Если уже есть заказы, но пассажиров в ТС нет - перенаправление заказов на другие ТС (функция временно недоступна, необходимо отменить заказ). 
                                            Направление ТС на заправку. 
                                            После заправки активация ТС для заказов. 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>У водителя появилась необходимость высадить пассажира вне радиуса 20 м от виртуальной остановки (при невозможности к ней подъехать)</td>
                                        <td className='p-12'>Завершить поездку через раздел «Карты».</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Существенное загрязнение салона ТС (невозможность выполнение заказов)</td>
                                        <td className='p-12'>
                                            Коммуникация проблемы начальнику автоколонны для ускорения ее решения, корректировка расписания водителя при необходимости / уведомление главного диспетчера о необходимости корректировки расписания
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Водитель сообщает о невозможности проезда в соответствии с информацией в приложении</td>
                                        <td className='p-12'>Направление информации специалистам ООО «ЦП КАМАЗ», предложить водителю альтернативный маршрут.</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Водитель не залогинился в системе вовремя?</td>
                                        <td className='p-12'>Позвонить водителю и уточнить причину опоздания. В случае наличия технической проблемы руководствоваться указаниям пункта "Приложение для водителя не отвечает". В случае неявки водителя скорректировать расписание смен (уточнить у диспетчеров в парке наличие резервных водителей в парке - при отсутствии перевести начало 2-й смены на данном ТС в автобусный парк и сообщить начальнику колонны) / уведомление главного диспетчера о необходимости корректировки расписания смен</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Водитель значительно задерживается в рамках подготовительного времени?</td>
                                        <td className='p-12'>Позвонить водителю и уточнить с чем возникла проблема, коммуникация проблемы начальнику автоколонны для ускорения ее решения</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Потеряна связь с водителем?</td>
                                        <td className='p-12'>Предложить водителю переместить ТС в другую локацию. Перезагрузить планшет. Проверить баланс сим-карты.
                                            Если предложенное решение не помогает - связаться с начальником автоколонны и предупредить о замене ТС на резервный, направить заявку на решение проблемы специалистам ООО «ЦП КАМАЗ»</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Водитель направляется не по указанному маршруту?</td>
                                        <td className='p-12'>Связаться с водителем и напомнить ему о необходимости следованию маршруту, в случае наличия препятствий (перекрытие дороги) направить заявку на решение проблемы специалистам ООО «ЦП КАМАЗ»</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Водитель значительно опаздывает на подбор пассажира?</td>
                                        <td className='p-12'>Позвонить водителю и уточнить с чем возникла проблема, коммуникация проблемы начальнику автоколонны для ускорения ее решения</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Водитель не вышел из системы, когда вышел из ТС?</td>
                                        <td className='p-12'>Удаленный выход из системы, напоминание водителю о необходимости проводить лог-аут из системы</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            <h3 className="manual-title t-c mt-24">Проактивное решение проблем</h3>
                            <div className="table-wrapper mt-24">
                                <table className="table">                     
                                <tbody>
                                    <tr>
                                        <td className='p-12'><b>Вопрос</b></td>
                                        <td className='p-12 licence-column'><b>Ответ</b></td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Водитель не залогинился в системе вовремя?</td>
                                        <td className='p-12'>Позвонить водителю и уточнить причину опоздания. В случае наличия технической проблемы руководствоваться указаниям пункта "Приложение для водителя не отвечает". В случае неявки водителя скорректировать расписание смен (уточнить у диспетчеров в парке наличие резервных водителей в парке - при отсутствии перевести начало 2-й смены на данном ТС в автобусный парк и сообщить начальнику колонны) / уведомление главного диспетчера о необходимости корректировки расписания смен</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Водитель значительно задерживается в рамках подготовительного времени?</td>
                                        <td className='p-12'>Позвонить водителю и уточнить с чем возникла проблема, коммуникация проблемы начальнику автоколонны для ускорения ее решения</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Потеряна связь с водителем?</td>
                                        <td className='p-12'>Предложить водителю переместить ТС в другую локацию. Перезагрузить планшет. Проверить баланс сим-карты.
                                            Если предложенное решение не помогает - связаться с начальником автоколонны и предупредить о замене ТС на резервный, направить заявку на решение проблемы специалистам ООО «ЦП КАМАЗ»</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Водитель направляется не по указанному маршруту?</td>
                                        <td className='p-12'>Связаться с водителем и напомнить ему о необходимости следованию маршруту, в случае наличия препятствий (перекрытие дороги) направить заявку на решение проблемы специалистам ООО «ЦП КАМАЗ»</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Водитель значительно опаздывает на подбор пассажира?</td>
                                        <td className='p-12'>Позвонить водителю и уточнить с чем возникла проблема, коммуникация проблемы начальнику автоколонны для ускорения ее решения</td>
                                    </tr>
                                    <tr>
                                        <td className='p-12'>Водитель не вышел из системы, когда вышел из ТС?</td>
                                        <td className='p-12'>Удаленный выход из системы, напоминание водителю о необходимости проводить лог-аут из системы</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            <h3 className="manual-title t-c mt-24">Высадка пассажира</h3>
                            <div className="table-wrapper mt-24">
                                <table className="table">                     
                                    <tbody>
                                        <tr>
                                            <td className='p-12'><b>Вопрос</b></td>
                                            <td className='p-12 licence-column'><b>Ответ</b></td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>Пассажир не выходит из ТС (спит / без сознания)</td>
                                            <td className='p-12'>Связаться с соответствующими службами (полиция, скорая помощь). Корректировка расписания водителя при необходимости</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h3 className="manual-title t-c mt-24">Ожидание заказов</h3>
                            <div className="table-wrapper mt-24">
                                <table className="table">                     
                                    <tbody>
                                        <tr>
                                            <td className='p-12'><b>Вопрос</b></td>
                                            <td className='p-12 licence-column'><b>Ответ</b></td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>Нет возможности встать на терминале ожидания</td>
                                            <td className='p-12'>Дать указания водителю по движению к свободному терминалу. При необходимости связаться с МГТ</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h3 className="manual-title t-c mt-24">Перерыв</h3>
                            <div className="table-wrapper mt-24">
                                <table className="table">                     
                                    <tbody>
                                        <tr>
                                            <td className='p-12'><b>Вопрос</b></td>
                                            <td className='p-12 licence-column'><b>Ответ</b></td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>При движении на перерыв система начала принимать заказы</td>
                                            <td className='p-12'>Разобраться в причине ситуации, перенаправить заказ на другие ТС (функция временно недоступна, необходимо отменить заказ). Перерыв начнется автоматически после окончания текущих поездок и продлится столько, сколько прописано в расписании.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h3 className="manual-title t-c mt-24">Перерыв</h3>
                            <div className="table-wrapper mt-24">
                                <table className="table">                     
                                    <tbody>
                                        <tr>
                                            <td className='p-12'><b>Вопрос</b></td>
                                            <td className='p-12 licence-column'><b>Ответ</b></td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>Сильное опоздание на перерыв</td>
                                            <td className='p-12'>Перерыв начнется автоматически после окончания текущих поездок и продлится столько, сколько прописано в расписании</td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>При движении на перерыв система начала принимать заказы</td>
                                            <td className='p-12'>Разобраться в причине ситуации, перенаправить заказ на другие ТС (функция временно недоступна, необходимо отменить заказ). Перерыв начнется автоматически после окончания текущих поездок и продлится столько, сколько прописано в расписании.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h3 className="manual-title t-c mt-24">Пересменка</h3>
                            <div className="table-wrapper mt-24">
                                <table className="table">                     
                                    <tbody>
                                        <tr>
                                            <td className='p-12'><b>Вопрос</b></td>
                                            <td className='p-12 licence-column'><b>Ответ</b></td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>Второй водитель опаздывает на пересменку</td>
                                            <td className='p-12'>Разобраться в причине ситуации и принять решение (например, о снятии ТС с маршрута без водителя; вызове резервного водителя; продлении смены водителя / уведомление главного диспетчера о необходимости продления смены; сделать соответствующие корректировки в расписании - обновить время, водителя, ТС)</td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>Мед работник отсутствует на ОРП</td>
                                            <td className='p-12'>Связаться с МГТ и выяснить причину отсутствия медработника. Перенаправить ТС и водителей на другой ОРП, вызывать резервного мед работника совместно с МГТ</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h3 className="manual-title t-c mt-24">От заказа до посадки</h3>
                            <div className="table-wrapper mt-24">
                                <table className="table">                     
                                    <tbody>
                                        <tr>
                                            <td className='p-12'><b>Вопрос</b></td>
                                            <td className='p-12 licence-column'><b>Ответ</b></td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>Водитель не может выполнить поездку (например, ДТП)</td>
                                            <td className='p-12'>Отметка ТС в системе как недоступного к приему заказов, завершение смены водителя в системе, координация вызова необходимых служб</td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>Разрядился/вышел из строя телефон</td>
                                            <td className='p-12'>Если пассажир обратится с другого телефона к диспетчеру, предложить пассажиру пройти идентификацию при посадке у водителя, назвав ему последние 6 цифр мобильного номера</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h3 className="manual-title t-c mt-24">Посадка</h3>
                            <div className="table-wrapper mt-24">
                                <table className="table">                     
                                    <tbody>
                                        <tr>
                                            <td className='p-12'><b>Вопрос</b></td>
                                            <td className='p-12 licence-column'><b>Ответ</b></td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>Не могу найти ТС</td>
                                            <td className='p-12'>Проверить расположение ТС, корректность заказа и пояснить пассажиру, как пройти до ТС (используя чат или звонок пассажиру)</td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>Нет свободных мест (при условии корректного заказа)</td>
                                            <td className='p-12'>
                                                Попросить водителя сохранять спокойствие и уточнить пассажиров, кто ранее сел в ТС без билета. При наличии ответа попросить пассажира выйти из транспортного средства. Если пассажиры молчат, то с помощью водителя объяснить пассажиру, что в данной ситуации лучшего всего будет пассажиру отменить поездку и вызвать ТС снова. 
                                                Если QR-код был отсканирован, то сделать форсированную высадку.
                                                Если QR не был отсканирован - предложить пассажиру или отменить поездку (холдирование средств пассажира будет отменено автоматически) или перебросить ее на другое ТС (функция временно недоступна, отменить заказ).
                                                Далее в любом случае заблокировать ТС для приема заказов до завершения поездки. Потом снова сделать ТС активным
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>ТС проехал мимо</td>
                                            <td className='p-12'>Разобраться в ситуации и принять решение, компенсировать ли пассажиру стоимость поездки или нет. Зарегистрировать случай с данным водителем</td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>Посадка с нарушениями правил провоза (животные / негабаритный багаж и пр.)</td>
                                            <td className='p-12'>Попросить водителя сохранять спокойствие - в случае возникновения вопросов по работе сервиса предложить пассажирам прочитать правила его пользования (на сайте, в Приложении, по QR коду, размещенному в ТС). При агрессивном поведении пассажира предупредить о вызове правоохранительных органов. В случае агрессивного поведения пассажира и угроз применить физическую силу по отношению к водителю – вызвать полицию.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h3 className="manual-title t-c mt-24">Проезд</h3>
                            <div className="table-wrapper mt-24">
                                <table className="table">                     
                                    <tbody>
                                        <tr>
                                            <td className='p-12'><b>Вопрос</b></td>
                                            <td className='p-12 licence-column'><b>Ответ</b></td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>Машина не может продолжить движение (поломка ТС, ДТП, длительная проверка ГИБДД и пр.)</td>
                                            <td className='p-12'>Связаться с ГИБДД при необходимости. Отметка ТС в системе как недоступного к приему заказов, завершение смены водителя в системе, координация вызова необходимых служб: организовать вывоз пассажиров силами других ТС; при нехватке ТС сервиса связаться с другими службами МГТ</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h3 className="manual-title t-c mt-24">Обратная связь</h3>
                            <div className="table-wrapper mt-24">
                                <table className="table">                     
                                    <tbody>
                                        <tr>
                                            <td className='p-12'><b>Вопрос</b></td>
                                            <td className='p-12 licence-column'><b>Ответ</b></td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>Поставил по ошибке</td>
                                            <td className='p-12'>Попросить пассажира оставить сообщение в Службе поддержки (канал письменной обратной связи)</td>
                                        </tr>
                                        <tr>
                                            <td className='p-12'>Забыл что-то в ТС</td>
                                            <td className='p-12'>Проверить через Приложение ТС, на котором осуществлял поездку пассажир и связаться с водителем, попросив забрать оставленную вещь и доставить на место хранения забытых вещей в парк. Сообщить пассажиру адрес и время работы места хранения</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </section>                                   
                            
                      </div>                           
                  </section>   
                  </div>
                   */}   


                  <footer id="footer" className="footer container-fixed mt-24">
                    <div className="d-fl fl-col fl-sm-row jc-sb cg-16 rg-32 mt-40">
                        <div className="w-100 w-sm-40 fade-in-bottom-small a-dr-05 js-scroll">
                            <h4 className="footer__header t-m mb-20">Перевозчик ООО «Камаз-спец-транс»</h4>
                            <p className="t-xs">ИНН 1657264932</p>
                            <p className="t-xs mt-4">ОГРН 1201600079394</p>
                            <p className="t-xs mt-12">
                                423800, Республика Татарстан, город Набережные Челны, улица Машиностроительная, дом 105
                            </p>
                        </div>

                        <div className="w-100 w-sm-40 fade-in-bottom-small a-dr-05 js-scroll">
                            <h4 className="footer__header t-m mb-20">Разработчик ООО «Цифровая платформа КАМАЗ»</h4>
                            <p className="t-xs">ИНН 1650369414</p>
                            <p className="t-xs mt-4">ОГРН 1650369414</p>
                            <p className="t-xs mt-12">423827, Республика Татарстан, город Набережные Челны, проспект Автозаводский, дом 2</p>
                        </div>

                        <div className="d-fl fl-col w-100 w-sm-40 fade-in-bottom-small a-dr-05 js-scroll">
                            <h4 className="footer__header t-m mb-20">Документы</h4>
                            <a
                                href="https://storage.yandexcloud.net/mt-docs/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.htm"
                                target="_blank"
                                className="footer__link t-xs d-inl-b"
                            >
                                Политика Конфиденциальности
                            </a>
                            <a
                                href="https://storage.yandexcloud.net/mt-docs/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%BF%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F%20%D0%B2%20%D1%81%D0%B0%D0%BB%D0%BE%D0%BD%D0%B5%20%D1%82%D1%80%D0%B0%D0%BD%D1%81%D0%BF%D0%BE%D1%80%D1%82%D0%BD%D0%BE%D0%B3%D0%BE%20%D1%81%D1%80%D0%B5%D0%B4%D1%81%D1%82%D0%B2%D0%B0.htm"
                                target="_blank"
                                className="footer__link t-xs d-inl-b mt-12"
                            >
                                Правила поведения в транспортном средстве
                            </a>
                            <a
                                href="https://storage.yandexcloud.net/mt-docs/%D0%A1%D0%9E%D0%93%D0%9B%D0%90%D0%A1%D0%98%D0%95%20%D0%9D%D0%90%20%D0%9E%D0%91%D0%A0%D0%90%D0%91%D0%9E%D0%A2%D0%9A%D0%A3%20%D0%9F%D0%95%D0%A0%D0%A1%D0%9E%D0%9D%D0%90%D0%9B%D0%AC%D0%9D%D0%AB%D0%A5%20%D0%94%D0%90%D0%9D%D0%9D%D0%AB%D0%A5.htm"
                                target="_blank"
                                className="footer__link t-xs d-inl-b mt-12"
                            >
                                Согласие на обработку персональных данных
                            </a>
                            <a
                                href="https://storage.yandexcloud.net/mt-docs/%D0%9F%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D0%BA%D0%BE%D0%B5%20%D1%81%D0%BE%D0%B3%D0%BB%D0%B0%D1%88%D0%B5%D0%BD%D0%B8%D0%B5.htm"
                                target="_blank"
                                className="footer__link t-xs d-inl-b mt-12"
                            >
                                Пользовательское соглашение мобильного приложения
                            </a>
                        </div>

                        <div className="w-100 w-sm-auto fade-in-bottom-small a-dr-05 js-scroll">
                            <h4 className="footer__header t-m mb-20">Мы в соц. сетях</h4>
                            <a href="https://t.me/chelnok_rf" target="blank" className="d-fl ai-c cg-12">
                                <span className="footer__icon icon-telegram"></span>
                                <p target="blank" className="footer__link t-sm">
                                    Телеграм
                                </p>
                            </a>

                            <a href="https://vk.com/chelnok_nch" target="blank" className="d-fl ai-c cg-12 mt-12">
                                <span className="footer__icon icon-vk"></span>
                                <p target="blank" className="footer__link t-sm">
                                    Вконтакте
                                </p>
                            </a>

                            <h4 className="footer__header t-m mt-20">Наша почта</h4>
                            <a href="mailto:support@chelnok.space&subject=Обратная связь Челнок" className="d-fl ai-c cg-12 mt-12">
                                <span>
                                    <img src="img/email.svg" />
                                </span>
                                <p target="blank" className="footer__link t-sm">
                                    support@chelnok.space
                                </p>
                            </a>
                        </div>
                    </div>

                    <div className="d-fl fl-w jc-sb mt-40 mb-16">
                        <p className="footer__label">Челнок 2023 г.</p>
                        <p className="footer__label">Все права защищены</p>
                    </div>
                </footer>

            </div>
        </div>
  )
}
